import React from 'react'
import styles from '../Names.module.css'

const Footer = () => {
  return (
    <div className={styles.footer}>
        Develop by Elishama Pintosevich
    </div>
  )
}

export default Footer