import React from 'react'
import styles from '../Header.module.css'
import img from '../assets/logoVaikra2.png'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const nav = useNavigate()
  return (
    <div className={styles.header}>
      <div className={styles.divTitle}>
        <div onClick={()=>{nav(-1)}} className={styles.title}>
        Воины Давида
        </div>
        <img className={styles.img} src={img} alt='logo of vaikra'/>
      </div>
    </div>
  )
}

export default Header