import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from '../Tehilim.module.css'
import axios from 'axios'
import Loading from './Loading'
import globalStorage from '../context/globalStorage'

const TehilimPgae = () => {
    const [tehilim, setTehilim]=useState([])
    const [loading, setLoading]=useState(false)
    
    const {book ,setBook} = useContext(globalStorage)
    const nav = useNavigate()
    useEffect(()=>{
       getData()
    })
    const getData = async()=>{
        try {
            const {data} = await axios.get(`https://good-action.cyclic.app/psalms`)
            // const {data2} = await axios.get(`https://good-action.cyclic.app/teilim/single/652bff00379fd0dc19b8729c`)
            // console.log(data2);
            let newText = data[params.id-1].split('.')
            const myText = newText.map((ele)=>{
                return(
                    <div key={Math.random()}>
                        <div>{ele}</div>
                        <br/>
                    </div>
                )
            })
            setTehilim(myText)
            setLoading(true)
            
        } catch (error) {
            console.log(error);
        }

    }
    const sendData = async(_data)=>{
        try {
            const {data} = await axios.put("https://good-action.cyclic.app/teilim/setPages/652bff00379fd0dc19b8729c",{
                name:"Теилим",
                count:150,
                pages:_data,
            } 
            )
            console.log(data);
        } catch (error) {
            console.log(error);
        }
}
const onPress = ()=>{
   
        console.log('start');
        // const {data} = await axios.get(`https://good-action.cyclic.app/tractates/single/${params.id}`)
        // const {data} = await axios.get(`https://good-action.cyclic.app/teilim/single/652bff00379fd0dc19b8729c`)
        console.log(book.pages);
        book.pages[params.id-1]=1
        setBook({...book})
        console.log(book.pages);
        // sendData(tract.pages)
        sendData(book.pages)
        nav(-1)
        // console.log(data);
}
    
    
    const params = useParams()
  return (
    <div className={styles.container}>
        {/* title, button language , botton link to video, div text, botton finish */}
        <div className={styles.title}>
             {params.id} Псалом
        </div> 
        <div className={styles.wrapLangVid}>
            <button className={styles.buttonLanguage}>Перевод на Русский</button>
            <a href='https://youtube.com/playlist?list=PLvZ6y9572htSimABz_1BT6BojAuM5lUAb&si=qZlG_6apYHtTFkxg' target='_blank' rel="noreferrer" >
                <div className={styles.linkVideo}>
                   <div className={styles.triangle}/>
                </div>
            </a>
        </div>
        <div>

          {loading ? <div className={styles.text}>
              {tehilim}
          </div>
          :
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
             <Loading/>
          </div>}
        </div>
        <div>
            <button onClick={()=>{onPress()}} className={styles.finish}>
            Прочитал
            </button>
        </div>
    </div>
  )
}

export default TehilimPgae