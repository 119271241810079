
import './App.css';
import AppRoutes from './AppRoutes';
import globalStorage from './context/globalStorage'
import { useState } from 'react';

function App() {
  const [book, setBook]=useState({})
  return (
    <div>
      <globalStorage.Provider value={{book, setBook}}>
           <AppRoutes/>
      </globalStorage.Provider>
    </div>
  );
}

export default App;
