import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import styles from '../Header.module.css'
import styles2 from '../Names.module.css'
import Loading from './Loading'
import globalStorage from '../context/globalStorage'

const OneTractate = () => {
    const params = useParams()
    
    const nav = useNavigate()
    const {book, setBook} = useContext(globalStorage)
   
    const [loading, setLoading]=useState(false)
   
    const [calc, setCalc] = useState("")
    
    useEffect(()=>{
        getData()
    })
    const getData = async()=>{
        try {
            
            const {data} = await axios.get(`https://good-action.cyclic.app/teilim/single/${params.id}`)
            setBook({...data[0]})
            console.log(data);
            onCheck(data[0].pages)
            setLoading(true)
        } catch (error) {
            console.log(error);
        }
    }
    const onCheck = (_data)=>{
            const sum = _data.reduce((sum, current)=> sum + current, 0)
            console.log(sum);
            setCalc(sum)
       
    }
    
    const onPress = (_id)=>{
        
            
            
            nav(`/psalom/${_id+1}`)
            
      

    }
    
    
  return (
    <div className={styles.text}>
        
        <div className={styles.subTitle}>
            <div>
              <div className={styles.miniTitle}>Зарядите свою душу силой псалмов</div>
              Давид, великий царь и пророк, оставил нам дар – мудрые слова и молитвы, известные как псалмы. Эти стихи несут в себе духовную мощь и вдохновение. Они могут стать вашими спутниками в борьбе с злом и поддержать ваши добрые дела.
            </div>
        </div>
    <div className={styles2.buttonDiv2}>
           <div className={styles2.button}>
              <button onClick={()=>{nav('/')}} className={styles2.link}>Вернуться назад </button>
           </div>
           <div className={styles.remain}>
           Прочитано {calc} Псалмов
           </div>
     </div>
    <div className={styles.buttons}>
        {loading ? book.pages.map((ele, i)=>{
            return(
                <div key={Math.random()} className={styles.buttonDiv}>
                    <button style={ ele ? {backgroundColor:'#A82626'} : {backgroundColor:'#265BA8'}} onClick={()=>{onPress(i);}} className={styles.button}>{i+1} {ele ? "✓" : ""}</button>
                     
                </div>
            )
        }) : <Loading/>}
    </div>
    </div>
  )
}

export default OneTractate